import React from 'react';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import 'dayjs/locale/es'; // load on demand
import ActionButton from '../../ActionButton/ActionButton';
dayjs.locale('es');

const PostElement = ({ askIfDelete, post }) => {
    let history = useHistory();
    const nextPath = (path) => {
        history.push(path);
    };
    return (
        <>
            <article className='each-post-prev'>
                <div className='info'>
                    <div className="title-btn">
                        <h5>{ post.title }</h5>
                        <div className='btn-group'>
                            <ActionButton type={ 'delete' } action={ () => askIfDelete(post._id) } />
                            <ActionButton type={ 'edit' } action={ () => nextPath(`/mfi/blog/editar-entrada/${post._id}`) } />
                        </div>

                    </div>
                    { post?.postDate ? <p>Fecha de publicación: <strong>{ dayjs(post?.postDate).format('DD/MM/YYYY') }</strong></p> : <p>Fecha de publicación: <strong>{ dayjs(post?.createdAt).format('DD/MM/YYYY') }</strong></p> }
                    <p>Fecha de la última edición: <strong>{ dayjs(post?.updatedAt).format('DD/MM/YYYY') }</strong></p>
                    <p className="status">Estado de la publicación: <strong>{ post.status }</strong></p>
                </div>
            </article>
        </>
    );
};

export default PostElement;

import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import { types } from '../../../types/types';
import { AuthContext } from '../../../reducers/auth/AuthContext';

import { AiOutlineBank, AiOutlineCloseCircle, AiOutlineComment, AiOutlineLayout, AiOutlineMail, AiOutlinePlusCircle, AiOutlineUserAdd } from 'react-icons/ai';
import Modal from 'react-bootstrap/Modal';
import SignupScreen from '../../auth/SignupScreen/SignupScreen';

export const DashboardNav = ({ handleShow }) => {
	const { dispatch, user } = useContext(AuthContext);
	const [currentNav, setCurrentNav] = useState();

	const handleLogout = async () => {
		await dispatch({ type: types.logout });
		localStorage.removeItem('default-token');
		localStorage.removeItem('default-user');
	};
	const [show, setShow] = useState(false);
	const handleCloseCat = () => setShow(false);
	const handleOpenCat = () => setShow(true);
	return (
		<>
			<nav className='dash-nav '>

				<Link to='/mfi' onClick={ () => setCurrentNav('') }>
					<figure>
						<img src={ process.env.REACT_APP_COMPANY_LOGO_BN } alt='' />
					</figure>
				</Link>

				<ul>
					<li>
						<Link to='/mfi/empresa' onClick={ () => setCurrentNav('empresa') }><AiOutlineBank className={ currentNav === 'empresa' ? 'active' : null } /><span>Empresa</span></Link>
					</li>
					<li>
						<Link to='/mfi/blog' onClick={ () => setCurrentNav('blog') }><AiOutlineComment className={ currentNav === 'blog' ? 'active' : null } /><span>Blog</span></Link>
					</li>
					<li>
						<Link to='/mfi/mensajes' onClick={ () => setCurrentNav('mensajes') }><AiOutlineMail className={ currentNav === 'mensajes' ? 'active' : null } /><span>Mensajes</span></Link>
					</li>
					<li>
						<Link to='/mfi/seccion' onClick={ () => setCurrentNav('secciones') }><AiOutlineLayout className={ currentNav === 'secciones' ? 'active' : null } /><span>Secciones</span></Link>
					</li>
				</ul>
				<div className='btn-group'>
					<AiOutlineUserAdd onClick={ handleOpenCat } />
					{ user.email === 'admin@yai.com' && (
						<AiOutlinePlusCircle onClick={ handleShow } />
					) }
					<AiOutlineCloseCircle onClick={ handleLogout } />
				</div>
				<Modal centered show={ show } onHide={ handleCloseCat }>
					<Modal.Header closeButton>
						<h4>Añadir Usuario nuevo</h4>
					</Modal.Header>
					<Modal.Body>
						<SignupScreen setShow={ setShow } />
					</Modal.Body>
				</Modal>

			</nav>
		</>
	);
};

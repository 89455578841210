import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import useForm from '../../hooks/useForm';

import { deleteSection, updateSection } from '../../actions/sections.action';

import { types } from '../../types/types';
import { AuthContext } from '../../reducers/auth/AuthContext';
import { SectionContext } from '../../reducers/sections/sectionsContext';

import { fileUpload } from '../../helpers/uploadFiles';

import { EditGroupSection } from './EditGroupSection';

import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import { FaTimesCircle } from "react-icons/fa";
import TextEditorSections from '../_ui/TextEditor/TextEditorSections';
import SectionTitle from '../_ui/SectionTitle/SectionTitle';
import ActionButton from '../_ui/ActionButton/ActionButton';

export const SectionScreen = () => {
	const { user } = useContext(AuthContext);
	const { dispatchSections, sections } = useContext(SectionContext);
	const { id } = useParams();
	const { values, setValues, handleInputChange, handleFileChange } = useForm();
	const { text, parsedText, _id: idSection, gallery, features, formInputs } = values;
	const history = useHistory();
	let thisSection = useRef();
	const [auxValue, setAuxValue] = useState();
	const [quill, setQuill] = useState();

	useEffect(() => {
		if (sections.length > 0) {
			thisSection.current = sections.find((sect) => sect._id === id);
			setValues(thisSection.current);
			setQuill(thisSection.current.text);
		}
	}, [id, sections, setValues]);

	const clearInput = (input) => {
		const theinput = document.getElementById(input);
		theinput.value = '';
	};

	//Features
	const deleteFeature = (idx) => {
		const featCopy = [...features];
		featCopy.splice(idx, 1);
		setValues({
			...values,
			features: featCopy,
		});
	};

	const handleFeatureChange = (e) => {
		setAuxValue(e.target.value);
	};

	const handleAddFeature = (e) => {
		e.preventDefault();
		if (!features) {
			setValues({
				...values,
				features: [auxValue],
			});
			clearInput('to-reset-features');
		} else if (!features?.includes(auxValue)) {
			setValues({
				...values,
				features: [...features, auxValue],
			});
			clearInput('to-reset-features');
		}
		clearInput('to-reset-features');
	};

	//Galeria
	const handleGalleryChange = async ({ target }) => {
		const file = target.files[0];
		const url = await fileUpload(file);

		setAuxValue(url);
	};

	const handleAddGallery = (e) => {
		e.preventDefault();
		if (!gallery) {
			setValues({
				...values,
				gallery: [auxValue],
			});
		} else if (!gallery?.includes(auxValue)) {
			setValues({
				...values,
				gallery: [...gallery, auxValue],
			});
		}
	};

	const deletePicture = (idx) => {
		const galleryCopy = [...gallery];
		galleryCopy.splice(idx, 1);
		setValues({
			...values,
			gallery: galleryCopy,
		});
	};

	//Form inputs
	const deleteFormInput = (idx) => {
		const inputCopy = [...formInputs];
		inputCopy.splice(idx, 1);
		setValues({
			...values,
			formInputs: inputCopy,
		});
	};

	const handleChangeFormInput = (e) => {
		setAuxValue(e.target.value);
	};

	const handleAddFormInput = (e) => {
		e.preventDefault();
		if (!formInputs) {
			setValues({
				...values,
				formInputs: [auxValue],
			});
			clearInput('to-reset-inputs');
		} else if (!formInputs?.includes(auxValue)) {
			setValues({
				...values,
				formInputs: [...formInputs, auxValue],
			});
			clearInput('to-reset-inputs');
		}
		clearInput('to-reset-inputs');
	};

	//Borrar propiedades

	const handleDelete = async () => {
		history.push('/mfi/seccion');
		const sectionGroup = await deleteSection(id);
		dispatchSections({ type: types.deleteSection, payload: sectionGroup });
	};

	const deleteField = async (property) => {
		setValues({
			...values,
			[property]: '',
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
	};
	const createHTLM = (text) => {
		return { __html: text };
	};


	const saveChanges = async () => {
		values.text = quill;
		values.parsedText = createHTLM(quill);

		const sectionUpdated = await updateSection(values, idSection);
		if (!sectionUpdated) {
			Swal.fire('¡Oh-oh!', 'Ha habido un error, inténtalo de nuevo', 'error');
		} else {
			Swal.fire('¡Bien!', 'Los cambios han sido guardados', 'success');
			dispatchSections({ type: types.sectionUpdate, payload: sectionUpdated });
			history.push('/mfi/seccion');
		}
	};
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const handleModalQuill = () => {
		setValues(v => ({ ...v, parsedText: createHTLM(quill) }));
		setShow(false);
	};
	return (
		<>
			<section className='section-screen'>
				<SectionTitle
					image={ process.env.REACT_APP_SECTIONS_IMAGE }
					title="Estás editando esta sección"
					instructions={ values?.sectionName }
				/>

				<div className="btn-group">
					<button className='my-btn mini' onClick={ saveChanges }>
						Guardar Cambios
					</button>

					{ user.email === 'admin@yai.com' && (
						<button className='my-btn mini danger' onClick={ handleDelete }>
							Borrar esta sección
						</button>
					) }
				</div>

				<EditGroupSection nameValue={ 'title' } deleteField={ deleteField } inputType='text' editLabel={ 'Título' } editAction={ handleInputChange } editValue={ values?.title } submitEdit={ handleSubmit } />
				<EditGroupSection
					nameValue={ 'subtitle' }
					deleteField={ deleteField }
					inputType='text'
					editLabel={ 'Subtítulo' }
					editAction={ handleInputChange }
					editValue={ values?.subtitle }
					submitEdit={ handleSubmit }
				/>
				<EditGroupSection
					imageEdit={ true }
					nameValue={ 'uniqueImage' }
					deleteField={ deleteField }
					inputType='file'
					editLabel={ 'Imagen principal' }
					editAction={ handleFileChange }
					editValue={ values?.uniqueImage }
					submitEdit={ handleSubmit }
				/>
				<section className='edit-group text-editor-group'>
					<div className="title-btn">
						<label>Texto:</label>
						<ActionButton type={ 'edit' } action={ handleShow } />
					</div>
					{ text && (
						<div dangerouslySetInnerHTML={ parsedText }></div>
					) }
				</section>

				<div className='edit-features'>
					<div className="title-btn">
						<label>Características:</label>
						<ActionButton type={ 'add' } action={ handleAddFeature } />
					</div>
					{ features?.length > 0 && (
						<div className='features'>
							{ features?.map((ft, idx) => (
								<div className='each-feat' key={ ft }>
									{ ft }
									<FaTimesCircle onClick={ () => deleteFeature(idx) } />
								</div>
							)) }
						</div>
					) }
					<div className='button-input-group'>
						<input type='text' id='to-reset-features' onChange={ handleFeatureChange } placeholder={ 'Añadir Característica' } name='features' />
					</div>
				</div>
				<div className='edit-features '>
					<div className="title-btn">
						<label>Formulario:</label>
						<ActionButton type={ 'add' } action={ handleAddFormInput } />
					</div>
					{ formInputs?.length > 0 && (
						<div className='features'>
							{ formInputs?.map((inp, idx) => (
								<div className='each-feat' key={ inp }>
									{ inp }
									<FaTimesCircle onClick={ () => deleteFormInput(idx) } />
								</div>
							)) }
						</div>
					) }
					<div className='button-input-group'>
						<input type='text' id='to-reset-inputs' onChange={ handleChangeFormInput } placeholder={ 'Añadir Input' } name='formInputs' />
					</div>
				</div>

				<div className='edit-gallery'>
					<div className="title-btn">
						<label>Galería:</label>
						<ActionButton type={ 'add' } action={ handleAddGallery } />
					</div>
					{ gallery?.length > 0 && (
						<div className='gallery'>
							{ gallery?.map((picture, idx) => (
								<figure className='each-picture' key={ idx }>
									<img src={ picture } alt='' />
									<FaTimesCircle className='close-icon' onClick={ () => deletePicture(idx) } />
								</figure>
							)) }
						</div>
					) }
					<div className='button-file-group'>
						<input type='file' onChange={ handleGalleryChange } placeholder={ 'Añadir Foto' } name='gallery' />
					</div>
				</div>

				<Modal size='lg' show={ show } onHide={ handleClose }>
					<Modal.Header closeButton >
						<h4>Editar texto de la sección</h4>
					</Modal.Header>
					<Modal.Body>
						<TextEditorSections setQuill={ setQuill } valores={ quill } />
						<button className='my-btn mini right' onClick={ handleModalQuill }>
							Guardar
						</button>
					</Modal.Body>
				</Modal>
			</section>
		</>
	);
};
